/* eslint-disable no-empty-pattern */
/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
import React from 'react';

import { useSelector } from 'react-redux';

import Link from 'next/link';
import Image from 'next/future/image';

function Navbar({ data, changeLanguage, language }) {
  const idFreeConsultation = 20;
  const idCourier = 2;
  const idInternational = 7;
  const idEmail = 22;

  const handleClickFreeConsul = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Link href="/" locale={language === 'ID' ? 'id' : 'en'}>
        <a>
          <Image
            src={data?.logo}
            alt={data?.alt_logo}
            // layout="fill"
            width={125}
            height={62}
            // loading="eager"
          />

          {/* <img src={data?.logo} alt={data?.alt_logo} /> */}
        </a>
      </Link>
      <ul className="top-nav-menu">
        {data?.HeaderMenus !== undefined &&
          data?.HeaderMenus.map(
            (item, index) =>
              item.id !== idFreeConsultation && (
                <>
                  {item.hasOwnProperty('child') ? (
                    <>
                      <li key={index}>
                        <span className="link-sub-nav">
                          {language === 'ID'
                            ? item.id_menu_name
                            : item.en_menu_name}
                        </span>
                        <ul className="sub-nav-content">
                          {item.child.map((child, childIndex) => (
                            <>
                              <li key={childIndex}>
                                {child.hasOwnProperty('id_url') ||
                                child.hasOwnProperty('en_url') ? (
                                  <Link
                                    href={
                                      language === 'ID'
                                        ? '/' + child?.id_url
                                        : '/' + child?.en_url
                                    }
                                    locale={language === 'ID' ? 'id' : 'en'}
                                  >
                                    <a>
                                      {language === 'ID'
                                        ? child.id_menu_name
                                        : child.en_menu_name}
                                    </a>
                                  </Link>
                                ) : (
                                  <span>
                                    {language === 'ID'
                                      ? child.id_menu_name
                                      : child.en_menu_name}
                                  </span>
                                )}
                                {child.hasOwnProperty('child') ? (
                                  <>
                                    <ul className="subs-nav">
                                      {child.child.map(
                                        (childofchild, childofchildIndex) => (
                                          <>
                                            <li key={childofchildIndex}>
                                              <Link
                                                href={
                                                  child?.id === idCourier
                                                    ? `/courier-services/${
                                                        language === 'EN'
                                                          ? childofchild?.en_url
                                                          : childofchild?.id_url
                                                      }`
                                                    : child.id ===
                                                      idInternational
                                                    ? `/international-delivery/${
                                                        language === 'EN'
                                                          ? childofchild?.en_url
                                                          : childofchild?.id_url
                                                      }`
                                                    : `/supply-chain/${
                                                        language === 'EN'
                                                          ? childofchild?.en_url
                                                          : childofchild?.id_url
                                                      }`
                                                }
                                                locale={
                                                  language === 'ID'
                                                    ? 'id'
                                                    : 'en'
                                                }
                                              >
                                                <a>
                                                  {language === 'ID'
                                                    ? childofchild.id_menu_name
                                                    : childofchild.en_menu_name}
                                                </a>
                                              </Link>
                                            </li>
                                          </>
                                        )
                                      )}
                                    </ul>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </li>
                            </>
                          ))}
                        </ul>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link
                          href={
                            language === 'ID'
                              ? '/' + item?.id_url
                              : '/' + item?.en_url
                          }
                          locale={language === 'ID' ? 'id' : 'en'}
                        >
                          <a>
                            {language === 'ID'
                              ? item.id_menu_name
                              : item.en_menu_name}
                          </a>
                        </Link>
                      </li>
                    </>
                  )}
                </>
              )
          )}
      </ul>

      <span className="right">
        <ul className="top-nav-menu top-consultation">
          {data?.HeaderMenus !== undefined &&
            data?.HeaderMenus.map(
              (item, index) =>
                item.id === idFreeConsultation && (
                  <li key={index}>
                    <span className="link-sub-nav">
                      {language === 'EN'
                        ? item.en_menu_name
                        : item.id_menu_name}
                    </span>
                    <ul className="sub-nav-content">
                      {item.child.map((child, childIndex) => (
                        <>
                          {child?.id === idEmail ? (
                            <li key={childIndex}>
                              <Link
                                href={
                                  language === 'ID'
                                    ? '/' + child?.id_url
                                    : '/' + child?.en_url
                                }
                                locale={language === 'ID' ? 'id' : 'en'}
                              >
                                <a>
                                  {language === 'ID'
                                    ? child.id_menu_name
                                    : child.en_menu_name}
                                </a>
                              </Link>
                            </li>
                          ) : (
                            <li
                              key={childIndex}
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleClickFreeConsul(
                                  language === 'EN'
                                    ? child.en_url
                                    : child.id_url
                                )
                              }
                            >
                              <a className="link-sub-nav">
                                {language === 'ID'
                                  ? child.id_menu_name
                                  : child.en_menu_name}
                              </a>
                            </li>
                          )}
                        </>
                      ))}
                    </ul>
                  </li>
                )
            )}
        </ul>

        <ul className="top-nav-menu">
          <li>
            <span>
              {/* <img src="../../../assets/icons/globe.png" alt="img" /> */}
              {/* <img src="../../../assets/icons/globe.png" alt="img" /> */}
              <Image
                src={`${process.env.URL_FRONTEND}/assets/icons/globe.png`}
                width={30}
                height={30}
                alt="img"
              />
              <span className="text-language">{language}</span>
            </span>
            <ul className="sub-nav-content">
              {language === 'ID' && (
                <li
                  onClick={changeLanguage}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  <span>EN</span>
                </li>
              )}

              {language === 'EN' && (
                <li
                  onClick={changeLanguage}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  <span>ID</span>
                </li>
              )}
            </ul>
          </li>
        </ul>
        <span className="home">
          {/* <img src="../../../assets/icons/home.png" alt="home" /> */}
          <Image
            src={`${process.env.URL_FRONTEND}/assets/icons/home.png`}
            width={39}
            height={30}
            alt="home"
          />
        </span>
      </span>
    </>
  );
}

export default Navbar;
