/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react';
// import { VectorMap } from 'react-jvectormap';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';

function ON({ data, language }) {
  const VectorMap = dynamic(
    () => import('react-jvectormap').then((m) => m.VectorMap),
    { ssr: false }
  );

  // useEffect(() => {
  //   document.querySelectorAll('g').forEach((element) => {
  //     element.class = "tes";
  //   });
  // }, [])

  const dispatch = useDispatch();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    if (typeof window !== 'undefined') {
      const { innerWidth, innerHeight } = typeof window !== 'undefined';
      return { innerWidth, innerHeight };
    }
  }

  const showInfo = () => {
    const elems = Array.from(document.querySelectorAll('.jvectormap-tip'));
    elems.pop();
    elems.map((node) => node.parentNode.removeChild(node));
    document.querySelectorAll('.jvectormap-tip').forEach((element) => {
      element.style.display = 'block';
    });
  };

  const hideInfo = () => {
    document.querySelectorAll('.jvectormap-tip').forEach((element) => {
      element.style.display = 'none';
    });
  };

  return (
    <>
      <div
        id='network'
        className='tabcontent_menu_ckl page-nav-tabs-our-network'
      >
        <div className='bg-overlay'></div>
        <div className='container'>
          <div className='col-content-our-network'>
            <div className='box-our-network'>
              <div className='row'>
                <div className='col-md-12'>
                  {/* get window size */}
                  {/* {windowSize.innerWidth}{windowSize.innerHeight} */}
                    <VectorMap
                      map={'indonesia'}
                      selectedRegions={data}
                      onRegionClick={(e, countryCode) => e.preventDefault()}
                      backgroundColor='transparent'
                      zoomOnScroll={false}
                      zoomButtons={false}
                      // containerStyle={{
                      //   width: '955px',
                      //   height: '400px',
                      //   borderRadius: '10px',
                      //   margin: '0px auto',
                      //   paddingLeft: '15px',
                      // }}
                      onRegionOver={(e, code) => {
                        showInfo();
                      }}
                      onRegionOut={(e, code) => {
                        hideInfo();
                      }}
                      // containerClassName='map'
                      regionStyle={{
                        initial: {
                          fill: 'white',
                          'fill-opacity': 1,
                          stroke: 'none',
                          'stroke-width': 0,
                          'stroke-opacity': 1,
                        },
                        hover: {
                          'fill-opacity': 0.8,
                          cursor: 'pointer',
                        },
                        selected: {
                          fill: 'yellow',
                        },
                      }}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ON;
