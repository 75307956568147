/* eslint-disable */
/* eslint-disable react/prop-types */
import Image from 'next/future/image';
import Link from 'next/link';
import React from 'react';

function SC({ data, language }) {
  const handleClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div id="supply" className="tabcontent_menu_ckl page-nav-tabs-supply">
        <div className="bg-overlay"></div>
        <div className="container">
          <div className="col-content-supply">
            <div className="col-box-for-sc">
              <h3
                dangerouslySetInnerHTML={{
                  __html:
                    language === 'EN'
                      ? data?.content?.en_title
                      : data?.content?.id_title
                }}
              ></h3>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    language === 'EN'
                      ? data?.content?.en_desc
                      : data?.content?.id_desc
                }}
              ></p>

              {data?.nav_menu_icon?.length > 0 ? (
                data?.nav_menu_icon?.map((item, index) => (
                  <button
                    className="btn-content-supply"
                    // onClick={() => handleClick(item.url)}
                    type="button"
                    key={index}
                  >
                    {item.url ? (
                      <Link
                        href={item.url}
                        locale={language === 'ID' ? 'id' : 'en'}
                      >
                        <a>
                          <Image
                            src={item?.icon_img}
                            alt={item?.alt_icon_img}
                            height={90}
                            width={90}
                          />

                          {/* <img src={item?.icon_img} alt={item?.alt_icon_img} /> */}

                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                language === 'EN'
                                  ? item?.en_title
                                  : item?.id_title
                            }}
                          ></p>
                        </a>
                      </Link>
                    ) : (
                      <>
                        <Image
                          src={item?.icon_img}
                          alt={item?.alt_icon_img}
                          width={550}
                          height={90}
                        />

                        {/* <img src={item?.icon_img} alt={item?.alt_icon_img} /> */}

                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              language === 'EN'
                                ? item?.en_title
                                : item?.id_title
                          }}
                        ></p>
                      </>
                    )}
                  </button>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SC;
