/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/prop-types */
import Image from "next/future/image";
import React from "react";
// import WA from "../../../public/assets/icons/icon-whatsapp.png";

function COR({ data, language }) {
  const handleClick = (url) => {
    window.open(`https://wa.me/${url}?text=`, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <div id="chat" className="tabcontent_menu_ckl page-nav-tabs-chat">
        <div className="bg-overlay"></div>
        <div className="container">
          <div className="col-content-chat">
            <p
              style={{
                color: "#ffffff",
                fontSize: "20px",
                fontWeight: "600",
                lineHeight: "1.4",
                letterSpacing: "0.42px",
                textAlign: "center",
                fontFamily: "IBM Plex Sans, sans-serif",
                transition: "transform 0.5s ease",
                marginBottom: "15px",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  language === "EN"
                    ? data?.content?.en_desc
                    : data?.content?.id_desc,
              }}
            ></p>
            <div className="col-btn-talk">
              <button
                className="btn-talk"
                onClick={() => handleClick(data?.content?.wa_no1)}
                type="button"
              >
                <Image
                  src={`${process.env.URL_FRONTEND}/assets/icons/icon-whatsapp.png`}
                  alt="icon"
                  width={550}
                  height={30}
                />

                {/* <img src="../../../assets/icons/icon-whatsapp.png" alt="icon" /> */}
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      language === "EN"
                        ? data?.content?.en_wa_btn_name1
                        : data?.content?.id_wa_btn_name1,
                  }}
                ></span>
              </button>

              <button
                className="btn-talk"
                onClick={() => handleClick(data?.content?.wa_no2)}
                type="button"
              >
                <Image
                  src={`${process.env.URL_FRONTEND}/assets/icons/icon-whatsapp.png`}
                  alt="icon"
                  width={550}
                  height={30}
                />

                {/* <img src="../../../assets/icons/icon-whatsapp.png" alt="icon" /> */}
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      language === "EN"
                        ? data?.content?.en_wa_btn_name2
                        : data?.content?.id_wa_btn_name2,
                  }}
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default COR;
