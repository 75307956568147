/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/prop-types */
import Image from 'next/future/image';
import Link from 'next/link';
import React from 'react';
// import WA from "../../../public/assets/icons/icon-whatsapp.png";

function IFF({ data, language }) {
  const handleClick = (url) => {
    window.open(`https://wa.me/${url}?text=`, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div
        id="international"
        className="tabcontent_menu_ckl page-nav-tabs-international"
      >
        <div className="bg-overlay"></div>
        <div className="container">
          <div className="col-content-international">
            <div className="col-box-for-iff">
              {data?.nav_menu_icon?.length > 0 ? (
                data?.nav_menu_icon?.map((item, index) => (
                  <button
                    key={index}
                    className="btn-content-international"
                    // onClick={() => handleProduct(item?.url)}
                    type="button"
                  >
                    {item.url ? (
                      <Link
                        href={item.url}
                        locale={language === 'ID' ? 'id' : 'en'}
                      >
                        <a>
                          <Image
                            src={item?.icon_img}
                            alt={item?.alt_icon_img}
                            width={550}
                            height={90}
                          />

                          {/* <img src={item?.icon_img} alt={item?.alt_icon_img} /> */}

                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                language === 'EN'
                                  ? item.en_title
                                  : item.id_title
                            }}
                          ></p>
                        </a>
                      </Link>
                    ) : (
                      <>
                        <Image
                          src={item?.icon_img}
                          alt={item?.alt_icon_img}
                          width={550}
                          height={90}
                        />

                        {/* <img src={item?.icon_img} alt={item?.alt_icon_img} /> */}

                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              language === 'EN' ? item.en_title : item.id_title
                          }}
                        ></p>
                      </>
                    )}
                  </button>
                ))
              ) : (
                <></>
              )}

              <div className="col-btn-talk">
                <button
                  className="btn-talk"
                  onClick={() => handleClick(data?.content?.wa_no1)}
                  type="button"
                >
                  <Image
                    src={`${process.env.URL_FRONTEND}/assets/icons/icon-whatsapp.png`}
                    alt="icon"
                    width={26}
                    height={26}
                  />

                  {/* <img src="../../../assets/icons/icon-whatsapp.png" alt="icon" /> */}

                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        language === 'EN'
                          ? data?.content?.en_wa_btn_name1
                          : data?.content?.id_wa_btn_name1
                    }}
                  ></span>
                </button>

                <button
                  className="btn-talk"
                  onClick={() => handleClick(data?.content?.wa_no2)}
                  type="button"
                >
                  <Image
                    src={`${process.env.URL_FRONTEND}/assets/icons/icon-whatsapp.png`}
                    alt="icon"
                    width={26}
                    height={26}
                  />

                  {/* <img src="../../../assets/icons/icon-whatsapp.png" alt="icon" /> */}

                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        language === 'EN'
                          ? data?.content?.en_wa_btn_name2
                          : data?.content?.id_wa_btn_name2
                    }}
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IFF;
