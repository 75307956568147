/* eslint-disable */
/* eslint-disable react/prop-types */
import Image from 'next/future/image';
import Link from 'next/link';
import React from 'react';

function CS({ data, language }) {
  return (
    <>
      <div
        id="courier"
        className="tabcontent_menu_ckl page-nav-tabs-courier-services"
      >
        <div className="bg-overlay"></div>
        <div className="container">
          <div className="col-content-courier-services">
            <h3
              dangerouslySetInnerHTML={{
                __html:
                  language === 'EN'
                    ? data?.content?.en_title
                    : data?.content?.id_title
              }}
            ></h3>
            {data?.nav_menu_icon?.length > 0 ? (
              data?.nav_menu_icon?.map((item, index) => (
                <button
                  className="btn-content-courier-services"
                  // onClick={() => handleClick(item.url)}
                  type="button"
                  key={index}
                >
                  {item.url ? (
                    <Link
                      href={item.url}
                      locale={language === 'ID' ? 'id' : 'en'}
                    >
                      <a>
                        <Image
                          src={item?.icon_img}
                          alt={item?.alt_icon_img}
                          width={550}
                          height={90}
                          //layout="raw" // layout="fill", layout="intrinsic"
                        />

                        {/* <img src={item?.icon_img} alt={item?.alt_icon_img} /> */}
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              language === 'EN'
                                ? item?.en_title
                                : item?.id_title
                          }}
                        ></p>
                      </a>
                    </Link>
                  ) : (
                    <>
                      {/* <img src={item?.icon_img} alt={item?.alt_icon_img} /> */}
                      <Image
                        src={item?.icon_img}
                        alt={item?.alt_icon_img}
                        width={550}
                        height={90}
                        //layout="raw" // layout="fill", layout="intrinsic"
                      />
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            language === 'EN' ? item?.en_title : item?.id_title
                        }}
                      ></p>
                    </>
                  )}
                </button>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CS;
