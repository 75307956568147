/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/prop-types */
// import Image from "next/image";
import Image from "next/future/image";
import React from "react";

function BottomNavigationIcon({ id, title, icon, alt_icon, onHover }) {
  return (
    <button
      className={id === 3 ? "tablinks tablinks-international" : "tablinks"}
      onMouseOver={onHover}
    >
      <div className="col-img">
        {/* <img src={icon} alt={alt_icon} /> */}

        <Image src={icon} alt={alt_icon} width={80} height={80} />
      </div>
      <p
        className="mt-4"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      ></p>
    </button>
  );
}

export default BottomNavigationIcon;
