import Navbar from "./navbar";
import BottomNavigationIcon from "./BottomNavigationIcon";
import MainContent from "./MainContent";
import COR from "./COR.js";
import CS from "./CS";
import IFF from "./IFF";
import ON from "./ON";
import SC from "./SC";
import WCU from "./WCU";

export { Navbar, BottomNavigationIcon, MainContent, COR, CS, IFF, ON, SC, WCU };
