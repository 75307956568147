/* eslint-disable react/prop-types */
import React from 'react';

import { WCU, CS, IFF, SC, ON, COR, BottomNavigationIcon } from '../index';

import { useState } from 'react';
import { useEffect } from 'react';

const dataBottomNavigation = [
  {
    id: 1,
    name: 'why'
  },
  {
    id: 2,
    name: 'courier'
  },
  {
    id: 3,
    name: 'international'
  },
  {
    id: 4,
    name: 'supply'
  },
  {
    id: 5,
    name: 'network'
  },
  {
    id: 6,
    name: 'chat'
  }
];

function MainContent({ data = [], CoverageArea, language }) {
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    let result = [];

    data?.map((item) => {
      dataBottomNavigation.map((nav) => {
        if (item.id === nav.id) {
          let obj = {
            ...item,
            name: nav.name
          };

          result.push(obj);
        }
      });
    });

    setNewData(result);
  }, [data]);

  function openCity(evt, cityName, bg, alt) {
    var i, tabcontent_menu_ckl, tablinks;

    // ambil background default (background image yang bukan dari navigasi)
    const tagA = document.querySelectorAll('#stage a');

    tagA?.forEach((item) => {
      item.style.display = 'none';
    });

    tabcontent_menu_ckl = document.getElementsByClassName(
      'tabcontent_menu_ckl'
    );
    for (i = 0; i < tabcontent_menu_ckl.length; i++) {
      tabcontent_menu_ckl[i].style.display = 'none';
    }

    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    document.getElementById(cityName).style.display = 'block';
    document.getElementById(cityName).style.backgroundImage = `url('${bg}')`;

    // set atribut alt img
    document.getElementById(cityName).alt = alt;
    evt.currentTarget.className += ' active';
  }

  return (
    <div className="main-canvas">
      <div className="main-content">
        <WCU data={data[0]} language={language} />
        <CS data={data[1]} language={language} />
        <IFF data={data[2]} language={language} />
        <SC data={data[3]} language={language} />
        <ON data={CoverageArea} language={language} />
        <COR data={data[5]} language={language} />

        <div className="tab-menu-icon-ckl">
          {newData?.map((item, index) => (
            <BottomNavigationIcon
              key={index}
              id={item?.id}
              alt_icon={item?.alt_icon_img}
              icon={item?.icon_img}
              title={language === 'EN' ? item.en_name : item.id_name}
              onHover={(e) =>
                openCity(
                  e,
                  item.name,
                  item.background_img,
                  item.alt_background_img
                )
              }
            />
          ))}
        </div>

        <div className="clearfix"></div>
      </div>
    </div>
  );
}

export default MainContent;
