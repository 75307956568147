/* eslint-disable */
/* eslint-disable react/prop-types */
import Image from "next/future/image";
import React from "react";

function WCU({ data, language }) {
  return (
    <>
      <div id="why" className="tabcontent_menu_ckl page-nav-tabs-why-choose-us">
        <div className="bg-overlay"></div>
        <div className="container">
          <div className="col-content-why-choose-us">
            <div className="col-box-why-choose-us">
              {data?.nav_menu_icon?.length > 0 ? (
                data?.nav_menu_icon?.map((item, index) => (
                  <button key={index} className="btn-content-why-choose-us">
                    {/* <img src={item?.icon_img} alt={item?.alt_icon_img} /> */}

                    <Image
                      src={item?.icon_img}
                      alt={item?.alt_icon_img}
                      height={40}
                      width={60}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          language === "EN" ? item.en_title : item.id_title,
                      }}
                    ></p>
                  </button>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WCU;
